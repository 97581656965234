//obtener datos de la url en caso de que sean enviados
const valores = window.location.search;

//Creamos la instancia de la url
const urlParams = new URLSearchParams(valores);

//Accedemos a los valores
const user_token = urlParams.has('user') ? urlParams.get('user') : null;
const go = urlParams.has('to') ? urlParams.get('to') : '';
if (user_token) {
    $.ajax({
        method: "GET",
        url: base_url + "token/login",
        headers: {
            Authorization: "Bearer " + user_token
        },
        beforeSend: function (xhr) {
            $("#global-loader").fadeIn("fast");
        }
    }).done(function (data) {
        if (data.status === 'success') {
            // location.reload();
            localStorage.setItem('access_token', data.data.token);
            // window.location = base_url + "panel";
            window.location = base_url + go;
        } else {
            basic_dialog('Error', data.message, 'error')
        }
    }).always(function (jqXHR) {
        let status = (jqXHR).status;
        if (status !== 'success') {
            $("#global-loader").fadeOut("slow");
        }
    }).fail(function (jqXHR) {
        let response = jqXHR.responseJSON
        let html_error = `<div class="alert alert-danger">
                                <ul>
                                    <li>${response.message}</li>
                                </ul>
                            </div>`
        $('#error-msg').append(html_error)
    });
}else{
    $("#global-loader").fadeOut("slow");
}

